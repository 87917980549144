import { createContext, useContext, useEffect, useState } from "react";

const RandomContext = createContext();
const RandomProvider = ({ children }) => {
  const [random, setRandom] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("random");
    if (data) {
      setRandom(false);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <RandomContext.Provider value={[random, setRandom]}>
      {children}
    </RandomContext.Provider>
  );
};

// Custom Hook
const useIsRandom = () => useContext(RandomContext);

export { useIsRandom, RandomProvider };
