import React from "react";
import Layout from "../../components/Layout/Layout/Layout";
import { useNavigate } from "react-router-dom";

const MinistryIndex = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <span
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-house-fill"></i>
                </span>
              </li>
              <li className="breadcrumb-item active">Ministry Books</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">Titles A-Z</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">CWWN</h5>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5
                        className="card-title text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("rcwwl")}
                      >
                        CWWL
                      </h5>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">Life-Sutdy</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        Conclusion of the NT
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        High Peak Books
                      </h5>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default MinistryIndex;
