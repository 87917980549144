import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout/Layout";
import axios from "axios";
import { useLocation, Link, useParams } from "react-router-dom";
import purify from "dompurify";
import { useAuth } from "../../../context/auth";

const Reading = () => {
  const { readingId } = useParams();

  const location = useLocation();
  const year = location.state?.year;
  const vol = location.state?.vol;
  const title = location.state?.title;
  const page = location.state?.page;
  let contentId;

  readingId !== undefined
    ? (contentId = readingId)
    : (contentId = location.state?.contentId);

  const [loading, setLoading] = useState(false);
  const [auth] = useAuth();
  const [datas, setDatas] = useState({});
  const [getYear, setGetYear] = useState("");
  const [getVol, setGetVol] = useState("");
  const [book, setBook] = useState("");
  const [index, setIndex] = useState(0);
  const [tableContent, setTableContent] = useState([]);
  const [totalIndex, setTotalIndex] = useState(0);
  const [nextTitleId, setNextTitleId] = useState(0);
  const [prevTitleId, setPrevTitleId] = useState(0);
  const [prevMainTitle, setPrevMainTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [dataSection, setDataSection] = useState([]);
  const [isBookmark, setIsBookmark] = useState(false);

  useEffect(() => {
    getReadContent();
  }, []);

  const getReadContent = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/api/v1/rcwwl/reading-content", {
        year,
        vol,
        title,
        page,
        contentId,
      });

      setDatas(data.reading);
      setGetYear(data.reading.cwwl_year);
      setGetVol(data.reading.cwwl_list_vol_id);
      setBook(data.book);
      setIndex(data.currentIndex);
      setTotalIndex(data.totalIndex);
      setNextTitleId(data.nextTitleId);
      setPrevTitleId(data.prevTitleId);
      setTableContent(data.tableContents);
      setSubject(data.reading.cwwl_title);
      setDataSection(data.dataSection);
      setPrevMainTitle(data.getPrevTitle);
      data.checkBookmark ? setIsBookmark(true) : setIsBookmark(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getPrevNextPage = async (prevNextId) => {
    const year = getYear;
    const vol = getVol;
    setLoading(true);

    try {
      const { data } = await axios.post("/api/v1/rcwwl/reading-content", {
        year,
        vol,
        prevNextId,
      });
      setDatas(data.reading);
      setGetYear(data.reading.cwwl_year);
      setGetVol(data.reading.cwwl_list_vol_id);
      setBook(data.book);
      setIndex(data.currentIndex);
      setTotalIndex(data.totalIndex);
      setNextTitleId(data.nextTitleId);
      setPrevTitleId(data.prevTitleId);
      setTableContent(data.tableContents);
      setDataSection(data.dataSection);
      setPrevMainTitle(data.getPrevTitle);

      data.checkBookmark ? setIsBookmark(true) : setIsBookmark(false);

      !data.reading.cwwl_title
        ? setSubject(subject)
        : setSubject(data.reading.cwwl_title);

      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    } catch (error) {
      console.log(error);
    }
  };

  const storeBookmark = async (
    readingId,
    source,
    year,
    vol,
    book,
    subject,
    section
  ) => {
    try {
      const userId = auth?.user._id;
      const { data } = await axios.post("/api/v1/bookmark/store-bookmark", {
        readingId,
        source,
        year,
        vol,
        book,
        subject,
        section,
        userId,
      });
      {
        data.message === "add" ? setIsBookmark(true) : setIsBookmark(false);
      }
    } catch (error) {}
  };

  return (
    <Layout>
      <div
        id="tabLink"
        className="align-items-center bg-white"
        style={{ display: "flex", width: "100%" }}
      >
        <div className="col-auto" style={{ width: "50%", display: "flex" }}>
          <div>
            <Link to="/auth/ministry">
              <i class="bi bi-house-fill"></i>
            </Link>
          </div>
          <div className="mx-3">|</div>
          <div>
            <Link to="/auth/ministry/rcwwl">CWWL</Link>
          </div>
        </div>
        <div className="col-auto text-end" style={{ width: "25%" }}>
          <select
            class="form-select"
            aria-label="Default select"
            onChange={(e) => getPrevNextPage(e.target.value)}
          >
            {tableContent.map((data, key) => (
              <>
                <option
                  selected={
                    prevMainTitle
                      ? prevMainTitle === data.cwwl_title
                      : subject === data.cwwl_title
                  }
                  key={key}
                  value={data._id}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: purify.sanitize(data.cwwl_title),
                    }}
                  />
                </option>
              </>
            ))}
          </select>
        </div>
        <div className="col-auto text-end" style={{ width: "12%" }}>
          <select
            class="form-select"
            aria-label="Default select"
            onChange={(e) => getPrevNextPage(e.target.value)}
          >
            {dataSection.map((data, key) => (
              <>
                <option
                  selected={datas.data_section === data.data_section}
                  key={key}
                  value={data._id}
                >
                  Section {data.data_section} of {dataSection.length}
                </option>
              </>
            ))}
          </select>
        </div>
        <div className="col-auto text-end" style={{ width: "5%" }}>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "instant" });
            }}
          >
            <i class="bi bi-arrow-bar-up"></i>
          </button>
        </div>
        <div
          className="col-auto text-end"
          style={{ width: "8%" }}
          id="prevNext"
        >
          {index !== 0 && (
            <span
              onClick={() => getPrevNextPage(prevTitleId)}
              style={{ cursor: "pointer" }}
            >
              <button type="button" class="btn btn-outline-success btn-sm">
                <i class="bi bi-chevron-left"></i>
              </button>
            </span>
          )}

          {index !== totalIndex - 1 && (
            <span
              className="ms-4"
              style={{ cursor: "pointer" }}
              onClick={() => getPrevNextPage(nextTitleId)}
            >
              <button type="button" class="btn btn-outline-success btn-sm">
                <i class="bi bi-chevron-right"></i>
              </button>
            </span>
          )}
        </div>
      </div>

      <main id="readingContent" className="main">
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="card overflow-auto">
                    <div className="card-body">
                      {loading ? (
                        <>
                          <div className="text-center">
                            <div
                              className="mb-3 fw-bold"
                              style={{ fontSize: "21px" }}
                            ></div>

                            <div
                              className="spinner-grow text-success spinner-grow-sm"
                              role="status"
                            >
                              <span className="sr-only" />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-center mt-3 fs-6 fw-bold">
                            <div id="bookTitle">{book}</div>
                            <div id="cwwlYearVol">
                              <i>CWWL, {getYear},</i> vol. {getVol}
                            </div>
                          </div>
                          <hr />
                          <div className="text-end mx-3 mb-3" id="bookmark">
                            <span
                              onClick={() =>
                                storeBookmark(
                                  datas._id,
                                  "cwwlcontents",
                                  year,
                                  vol,
                                  book,
                                  subject,
                                  datas.data_section
                                )
                              }
                            >
                              {!isBookmark ? (
                                <i class="bi bi-bookmark"></i>
                              ) : (
                                <i
                                  class="bi bi-bookmark-fill"
                                  style={{ color: "#cc9900" }}
                                ></i>
                              )}
                            </span>
                          </div>
                          <div
                            id="contentBody"
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: purify.sanitize(datas.cwwl_content),
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Reading;
