import React, { useState } from "react";
import purify from "dompurify";
import { Link } from "react-router-dom";
import "../../../styles/reading.css";

const ListContent = ({ data, year, vol }) => {
  return (
    <>
      {data ? (
        <div
          style={{
            borderTop: "1px solid #2c8a25",
            borderBottom: "1px solid #2c8a25",
            fontSize: "17px",
            padding: "8px 0px 8px 21px",
            marginBottom: "8px",
          }}
        >
          {data.map((list, key) => (
            <>
              <div className="mb-2" key={key}>
                {list.indexNo && <span className="mx-2">{list.indexNo}.</span>}
                {list.belong !== "subject" &&
                list.belong !== "subsubject" &&
                list.belong !== "contentnumnoclick" ? (
                  <>
                    <Link
                      style={{
                        color:
                          list.belong == "subsubjectspecial"
                            ? "#3474A8"
                            : "#000",
                      }}
                      to="/auth/ministry/reading"
                      state={{
                        year: year,
                        vol: vol,
                        title: list.title,
                        page: list.pageNo,
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(list.title),
                        }}
                      />
                    </Link>
                  </>
                ) : (
                  <>
                    {list.belong == "subject" ? (
                      <span
                        style={{ color: "#165411" }}
                        className="listSubject"
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(list.title),
                        }}
                      />
                    ) : (
                      <span
                        className={
                          list.belong !== "contentnumnoclick"
                            ? "listSubSubject fw-bold"
                            : ""
                        }
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(list.title),
                        }}
                      />
                    )}
                  </>
                )}

                <span
                  className="mx-2"
                  style={{ fontSize: "14px", color: "#A7A7A7" }}
                >
                  {list.pageNo}
                </span>
              </div>
            </>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ListContent;
