import React from "react";
import Layout from "../components/Layout/Layout/Layout";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <main id="main" className="main">
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">RCV Bible</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5
                        className="card-title text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/auth/ministry")}
                      >
                        Ministry Books
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card info-card">
                    <div className="card-body">
                      <h5 className="card-title text-center">属灵书报</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Dashboard;
