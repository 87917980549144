import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/auth";
import { useNavigate, NavLink } from "react-router-dom";
import { useIsResearch } from "../../../context/search";
import { useIsRandom } from "../../../context/random";

const Header = () => {
  const [auth, setAuth] = useAuth();
  const [page, setPage] = useIsResearch(0);
  const [random, setRandom] = useIsRandom(false);
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState("");

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };

  const handleOptionChange = () => {
    setRandom(!random);
    setPage(0);
  };

  const navigateSearch = (e) => {
    e.preventDefault();
    navigate(`/auth/search/${searchData}`);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    setPage(0);
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <span
          className="logo d-flex align-items-center"
          onClick={() => navigate("/")}
        >
          Reading
        </span>
      </div>
      {auth?.user !== null && (
        <>
          <form onSubmit={navigateSearch}>
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                value={searchData}
                placeholder=""
                required
                onChange={(e) => setSearchData(e.target.value)}
              />
              <button className="btn btn-primary" type="submit" title="Search">
                <i className="bi bi-search" />
              </button>
            </div>
          </form>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={random}
                onChange={handleOptionChange}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Random Search
              </label>
            </div>
          </div>
        </>
      )}

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item d-block d-lg-none">
            <a className="nav-link nav-icon search-bar-toggle " href="#">
              <i className="bi bi-search" />
            </a>
          </li>
          {auth?.user !== null ? (
            <>
              <li className="nav-item dropdown">
                <span
                  className="nav-link nav-icon"
                  id="bookmark"
                  onClick={() => navigate("/auth/bookmark")}
                >
                  <i
                    className="bi bi-bookmark-fill"
                    style={{ color: "#cc9900" }}
                  />
                </span>
              </li>
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link nav-icon"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="bi bi-chat-left-text" />
                  <span className="badge bg-success badge-number">0</span>
                </a>
?              </li> */}
              <li className="nav-item dropdown pe-3">
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <span className="d-none d-md-block dropdown-toggle ps-2">
                    {auth?.user.name}
                  </span>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"
                  style={{ zIndex: "1000" }}
                >
                  {/* <li>
                    <hr className="dropdown-divider" />
                  </li> */}
                  {auth?.user && (
                    <NavLink
                      onClick={handleLogout}
                      to="/login"
                      className="nav-link"
                    >
                      <li>
                        <a className="dropdown-item d-flex align-items-center">
                          <i className="bi bi-box-arrow-right" />
                          <span>Sign Out</span>
                        </a>
                      </li>
                    </NavLink>
                  )}
                </ul>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item pe-3 fw-bold">
                <span>
                  <NavLink to="/login" className="nav-link">
                    Login
                  </NavLink>
                </span>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
