import React, { useEffect, useState } from "react";
import purify from "dompurify";
import axios from "axios";

const Bible = () => {
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    getReadContent();
  }, []);

  const getReadContent = async () => {
    try {
      const { data } = await axios.post("/api/v1/rcwwl/cwwn", {});

      setDatas(data.list);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <table className="table datatable">
        <tbody>
          {datas.map((data, key) => (
            <>
              {data.head1 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.head1),
                      }}
                    />
                  </td>
                </tr>
              )}
              {data.head2 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.head2),
                      }}
                    />
                  </td>
                </tr>
              )}
              {data.head3 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <span
                      style={{ fontStyle: "italic" }}
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.head3),
                      }}
                    />
                  </td>
                </tr>
              )}
              {data.mainc1 && data.mainc2 && data.mainc3 && (
                <tr>
                  <td className="fw-bold">{data.mainc1}</td>
                  <td className="fw-bold">{data.mainc2}</td>
                  <td className="fw-bold">{data.mainc3}</td>
                </tr>
              )}
              {data.mainc1 && data.mainc2 && data.mainc3 == null && (
                <tr>
                  <td className="fw-bold">{data.mainc1}</td>
                  <td className="fw-bold">{data.mainc2}</td>
                  <td></td>
                </tr>
              )}
              {data.contentc1 && data.contentc2 && data.contentc3 && (
                <tr>
                  <td>{data.contentc1}</td>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.contentc2),
                      }}
                    />
                  </td>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.contentc3),
                      }}
                    />
                  </td>
                </tr>
              )}
              {data.contentc1 == null && data.contentc2 && data.contentc3 && (
                <tr>
                  <td></td>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.contentc2),
                      }}
                    />
                  </td>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.contentc3),
                      }}
                    />
                  </td>
                </tr>
              )}
              {data.subject && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <span
                      style={{ fontStyle: "italic" }}
                      dangerouslySetInnerHTML={{
                        __html: purify.sanitize(data.subject),
                      }}
                    />
                  </td>
                </tr>
              )}
              {data.contentc1 == null &&
                data.contentc2 &&
                data.contentc3 == null && (
                  <tr>
                    <td></td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: purify.sanitize(data.contentc2),
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Bible;
