import React, { useState, useEffect } from "react";
import ListContent from "./ListContent";
import axios from "axios";
import Layout from "../../../components/Layout/Layout/Layout";
import { useNavigate } from "react-router-dom";
import "../../../styles/reading.css";

const TableList = () => {
  const navigate = useNavigate();

  const [parentData, setParentData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [yearExpandedRow, setYearExpandedRow] = useState(null);
  const [volExpandedRow, setVolExpandedRow] = useState(null);

  const [recordId, setRecordId] = useState("");
  const [showProduct, setShowProduct] = useState(false);

  const toggleDetail = (recordId) => {
    setRecordId(recordId);
  };

  useEffect(() => {
    getTableContents();
  }, []);

  const getTableContents = async () => {
    try {
      const { data } = await axios.get("/api/v1/rcwwl/toc-list");
      if (data?.success) {
        setParentData(data?.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowClick = async (year, volume) => {
    const { data } = await axios.post("/api/v1/rcwwl/toc-list-content/", {
      year,
      volume,
    });

    setChildData(data.list[0].lists);
    setYearExpandedRow(data.list[0].year);
    setVolExpandedRow(data.list[0].vol);
  };

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <span
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-house-fill"></i>
                </span>
              </li>
              <li className="breadcrumb-item">
                <span
                  onClick={() => navigate("/auth/ministry")}
                  style={{ cursor: "pointer" }}
                >
                  Ministry Books
                </span>
              </li>
              <li className="breadcrumb-item active">CWWL</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title text-center">
                        Table of Contents
                      </h5>
                      <table
                        className="table"
                        id="cwwlToc"
                        style={{ marginBottom: "0px" }}
                      >
                        <tbody>
                          {parentData.map((parentItem, key) => (
                            <React.Fragment key={parentItem.id}>
                              <tr
                                key={key}
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDetail(parentItem._id)}
                              >
                                <td
                                  className="fw-bold"
                                  style={{
                                    fontSize: "17px",
                                    border: "1px solid #d4cec3",
                                    paddingLeft: "15px",
                                    color: "#1e0f00",
                                  }}
                                >
                                  {parentItem.cwwl_info} (
                                  {parentItem.cwwl_volume} volumes)
                                </td>
                              </tr>
                              <tr
                                style={{
                                  display:
                                    recordId === parentItem._id && !showProduct
                                      ? ""
                                      : "none",
                                }}
                              >
                                <td>
                                  {Array.from({
                                    length: parentItem.cwwl_volume,
                                  }).map((data, index) => (
                                    <>
                                      <div
                                        id="cwwlTblVol"
                                        onClick={() =>
                                          handleRowClick(
                                            parentItem.cwwl_info,
                                            index + 1
                                          )
                                        }
                                        className="mb-2"
                                      >
                                        Volume {index + 1}
                                      </div>

                                      {yearExpandedRow ===
                                        parentItem.cwwl_info &&
                                        volExpandedRow === index + 1 && (
                                          <div>
                                            <ListContent
                                              data={childData}
                                              year={yearExpandedRow}
                                              vol={volExpandedRow}
                                            />
                                          </div>
                                        )}
                                    </>
                                  ))}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default TableList;
