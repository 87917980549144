import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout/Layout";
import purify from "dompurify";
import useInfiniteScroll from "../useInfiniteScroll";
import { useIsResearch } from "../../context/search";
import { useIsRandom } from "../../context/random";

const SearchPage = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { searchData } = useParams();
  const [random, setRandom] = useIsRandom(false);
  // Setting tha initial page
  const [page, setPage] = useIsResearch(0);
  // Need to know if there is more data
  const [HasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    loadMoreItems();
  }, [searchData, random]);

  const prevRandomRef = useRef(random);

  // Infinite Scroll
  const [lastElementRef] = useInfiniteScroll(
    HasMore ? loadMoreItems : () => {},
    isFetching
  );

  // Search Product
  function loadMoreItems() {
    if (prevRandomRef.current !== random) {
      setItems([]);
      prevRandomRef.current = random;
    }
    if (page === 0) {
      setItems([]);
      setLoading(true);
    }

    setIsFetching(true);

    axios({
      method: "GET",
      url: `/api/v1/search/${searchData}`,
      params: { _page: page, _limit: 5, random },
    })
      .then((res) => {
        setItems((prev) => {
          return [...new Set([...prev, ...res.data.response.items])];
        });

        setPage((page) => page + 1);
        setHasMore(res.data.response.items.length > 0);
        setIsFetching(false);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const gethighlightedtext = (text, highlight) => {
    if (random == false) {
      // Extract text within <p> tags
      const paragraphs =
        text.match(/<(?:p|h2|li).*?>(.*?)<\/(?:p|h2|li)>/g) || [];
      // Find the first paragraph containing the highlight word or phrase (case-insensitive)
      const highlightedParagraph = paragraphs.find((paragraph) => {
        const plainText = paragraph.replace(/<[^>]+>/g, ""); // Remove HTML tags
        return plainText.toLowerCase().includes(highlight.toLowerCase());
      });
      if (highlightedParagraph) {
        // Highlight the occurrences of the highlight word or phrase within the paragraph
        const highlightedText = highlightedParagraph.replace(
          new RegExp(`(${highlight})`, "gi"),
          `<span class='highlight'>$1</span>`
        );

        return (
          <div
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(highlightedText),
            }}
          ></div>
        );
      }
      return null;
    } else {
      const arr = highlight.split(" ");
      const sentences = text.split("<p>");
      const highlightedSentences = sentences.map((paragraph) => {
        const words = paragraph.replace(/<[^>]+>/g, "").split(" ");
        const highlightedSentence = words.map((word, index) => {
          return word;
        });
        return highlightedSentence.join(" ");
      });
      const filteredParagraphs = highlightedSentences.filter((paragraph) =>
        arr.some((highlightWord) =>
          paragraph.toLowerCase().includes(highlightWord.toLowerCase())
        )
      );
      const getText = filteredParagraphs.join("");
      const words = arr.map(function (word) {
        return word.toLowerCase();
      });
      const parts = getText
        .split(" ")
        .map(function (word) {
          const trimmedWord = word.replace(
            /^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g,
            ""
          );
          return words.indexOf(trimmedWord.toLowerCase()) >= 0
            ? "<span class='highlight'>" + word + "</span>"
            : word;
        })
        .join(" ");

      return (
        <>
          {filteredParagraphs.length > 0 && (
            <div
              dangerouslySetInnerHTML={{
                __html: purify.sanitize(parts),
              }}
            ></div>
          )}
        </>
      );
    }
  };

  return (
    <Layout>
      <div
        id="tabLink"
        className="align-items-center bg-white"
        style={{ display: "flex", width: "100%" }}
      >
        <div className="col-auto text-end" style={{ width: "100%" }}>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "instant" });
            }}
          >
            <i className="bi bi-arrow-bar-up"></i>
          </button>
        </div>
      </div>

      <main id="searchContent" className="main">
        {loading ? (
          <>
            <div className="text-center">
              <div
                className="spinner-grow text-success spinner-grow-sm"
                role="status"
              >
                <span className="sr-only" />
              </div>
            </div>
          </>
        ) : (
          <>
            {items.map((info, index) => {
              if (items.length === index + 1) {
                return (
                  <>
                    <div id="searchBox">
                      <Link
                        target="_blank"
                        style={{ color: "#165411" }}
                        to={`/auth/ministry/reading/${info._id}`}
                      >
                        <div id="searchTitle">
                          <span className="me-3" style={{ color: "#825a1a" }}>
                            CWWL, {info.cwwl_year}, vol.{info.cwwl_list_vol_id}
                          </span>
                          {info.series} -{" "}
                          {info?.cwwl_title && (
                            <span className="me-2">{info?.cwwl_title}</span>
                          )}
                          {info?.cwwl_chapter && (
                            <span style={{ textTransform: "capitalize" }}>
                              {info.cwwl_chapter?.toLowerCase()}
                            </span>
                          )}
                          ,
                          {info?.data_section && (
                            <span className="ms-2">
                              Section {info.data_section}
                            </span>
                          )}
                        </div>
                      </Link>
                      <div>
                        {gethighlightedtext(info.cwwl_content, searchData)}
                      </div>
                    </div>
                    {isFetching ? (
                      <div className="text-center">
                        <div
                          className="spinner-grow text-warning"
                          role="status"
                        >
                          <span className="sr-only" />
                        </div>
                        <span
                          className="ms-2 fw-bold"
                          style={{ color: "#dc3545" }}
                        >
                          Please wait, loading more ...
                        </span>
                      </div>
                    ) : (
                      <div
                        ref={lastElementRef}
                        key={index}
                        className="endData text-center fw-bold"
                      >
                        Items retrieve completed
                      </div>
                    )}
                  </>
                );
              } else {
                return (
                  <>
                    <div id="searchBox" key={index}>
                      <Link
                        target="_blank"
                        style={{ color: "#165411" }}
                        to={`/auth/ministry/reading/${info._id}`}
                      >
                        <div id="searchTitle">
                          <span className="me-3" style={{ color: "#825a1a" }}>
                            CWWL, {info.cwwl_year}, vol.{info.cwwl_list_vol_id}
                          </span>
                          {info.series} -{" "}
                          {info?.cwwl_title && (
                            <span className="me-2">{info?.cwwl_title}</span>
                          )}
                          {info?.cwwl_chapter && (
                            <span style={{ textTransform: "capitalize" }}>
                              {info.cwwl_chapter?.toLowerCase()}
                            </span>
                          )}
                          ,
                          {info?.data_section && (
                            <span className="ms-2">
                              Section {info.data_section}
                            </span>
                          )}
                        </div>
                      </Link>
                      <div>
                        {gethighlightedtext(info.cwwl_content, searchData)}
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </>
        )}
      </main>
    </Layout>
  );
};

export default SearchPage;
