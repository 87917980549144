import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout/Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import purify from "dompurify";
import { useAuth } from "../context/auth";

const BookmarkIndex = () => {
  const navigate = useNavigate();
  const [bookmarkList, setBookmarkList] = useState([]);
  const [auth] = useAuth();

  useEffect(() => {
    getBookmarkList();
  }, []);

  const getBookmarkList = async () => {
    const userId = auth?.user._id;

    try {
      const { data } = await axios.post("/api/v1/bookmark/lists", {
        userId,
      });

      setBookmarkList(data.getBookmarks);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBookmark = async (bookmarkId) => {
    try {
      const { data } = await axios.post("/api/v1/bookmark/delete-bookmark", {
        bookmarkId,
      });
      setBookmarkList(bookmarkList.filter((item) => item._id !== bookmarkId));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <span
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-house-fill"></i>
                </span>
              </li>
              <li className="breadcrumb-item active">Bookmark</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="card overflow-auto">
                    <div className="card-body" id="contentBody">
                      <table className="table" id="bookmarkTable">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Content</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {bookmarkList.map((data, key) => (
                            <tr>
                              <td>
                                {moment(data.createdAt).format(
                                  "YYYY-MM-DD HH:mm"
                                )}
                              </td>
                              <td>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: purify.sanitize(data.info),
                                  }}
                                />
                              </td>
                              <td className="text-end">
                                <span onClick={() => deleteBookmark(data._id)}>
                                  <i
                                    class="bi bi-trash3"
                                    style={{ color: "red", cursor: "pointer" }}
                                  ></i>
                                </span>
                                <span className="ms-3">
                                  <Link
                                    style={{ color: "#165411" }}
                                    to="/auth/ministry/reading"
                                    state={{
                                      contentId: data.sourceId,
                                    }}
                                  >
                                    <i
                                      class="bi bi-box-arrow-up-right"
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BookmarkIndex;
