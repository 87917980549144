import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import MinistryIndex from "./pages/Ministry";
import TableList from "./pages/Ministry/Rcwwl/TableList";
import Reading from "./pages/Ministry/Rcwwl/Reading";
import ReadToc from "./pages/Ministry/Rcwwl/ReadToc";
import Bible from "./pages/Ministry/Rcwwn/bible";
import BookmarkIndex from "./pages/bookmark";
import SearchPage from "./pages/Ministry/searchPage";
import PageNotFound from "./pages/PageNotFound";
import Login from "./pages/Auth/Login";
import UserRoute from "./Routes/UserRoute";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/auth" element={<UserRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="ministry" element={<MinistryIndex />} />
          <Route path="ministry/rcwwl" element={<TableList />} />
          <Route path="ministry/reading-toc" element={<ReadToc />} />
          <Route path="ministry/reading" element={<Reading />} />
          <Route path="ministry/reading/:readingId" element={<Reading />} />
          <Route path="ministry/cwwn" element={<Bible />} />
          <Route path="bookmark" element={<BookmarkIndex />} />
          <Route path="search/:searchData" element={<SearchPage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
