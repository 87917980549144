import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout/Layout/Layout";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import purify from "dompurify";

const ReadToc = () => {
  const location = useLocation();
  const year = location.state.year;
  const vol = location.state.vol;
  const title = location.state.title;

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    getReadContent();
  }, []);

  const getReadContent = async () => {
    try {
      const { data } = await axios.post("/api/v1/rcwwl/reading-table-list", {
        year,
        vol,
        title,
      });

      setDatas(data.readings);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle d-flex">
          <div>
            <Link style={{ color: "#165411" }} to="/auth/ministry">
              <i class="bi bi-house-fill"></i>
            </Link>
          </div>
          <div className="mx-3">|</div>
          <div>
            <Link style={{ color: "#165411" }} to="/auth/ministry/rcwwl">
              CWWL
            </Link>
          </div>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="card overflow-auto">
                    <div className="card-body">
                      <div className="text-center mt-3 mb-3 fs-6 fw-bold">
                        <div
                          id="bookTitle"
                          dangerouslySetInnerHTML={{
                            __html: purify.sanitize(title),
                          }}
                        />
                        <div id="cwwlYearVol">
                          CWWL, {year}, vol. {vol}
                        </div>
                      </div>
                      <hr />
                      <div id="contentBody">
                        <div className="toc">Table of Contents</div>
                        {datas.map((data, index) => (
                          <>
                            {data.lists.belong !== "subsubject" ? (
                              <Link
                                style={{ color: "#165411" }}
                                to="/ministry/reading"
                                state={{
                                  year: year,
                                  vol: vol,
                                  title: data.lists.title,
                                  page: data.lists.pageNo,
                                }}
                              >
                                <div>
                                  <span>
                                    {data.lists.indexNo}
                                    {data.lists.indexNo && ". "}
                                  </span>
                                  <span
                                    className="mx-2"
                                    dangerouslySetInnerHTML={{
                                      __html: purify.sanitize(data.lists.title),
                                    }}
                                  />
                                  <span className="pg">
                                    {data.lists.pageNo}
                                  </span>
                                </div>
                              </Link>
                            ) : (
                              <>
                                <div>
                                  <span
                                    className="mx-2"
                                    dangerouslySetInnerHTML={{
                                      __html: purify.sanitize(data.lists.title),
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ReadToc;
