import { createContext, useContext, useEffect, useState } from "react";

const SearchContext = createContext();
const SearchProvider = ({ children }) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    const data = localStorage.getItem("page");
    if (data) {
      setPage(0);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <SearchContext.Provider value={[page, setPage]}>
      {children}
    </SearchContext.Provider>
  );
};

// Custom Hook
const useIsResearch = () => useContext(SearchContext);

export { useIsResearch, SearchProvider };
