import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/login", {
        username,
        password,
      });
      if (res && res.data.success) {
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        localStorage.setItem("auth", JSON.stringify(res.data));
        {
          navigate(location.state || "/");
        }
      } else {
        localStorage.removeItem("auth");
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <section className="h-100">
      <div className="container h-100">
        <div className="row justify-content-sm-center h-100">
          <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
            <div className="text-center my-5">
              <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>
            </div>
            <div className="card shadow-lg">
              <div className="card-body p-4">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="mb-2 text-muted" htmlFor="username">
                      Username
                    </label>
                    <input
                      id="username"
                      autoFocus
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type="text"
                      className="form-control"
                      name="username"
                      required
                    />
                    <div className="invalid-feedback">Username is invalid</div>
                  </div>
                  <div className="mb-3">
                    <div className="mb-2 w-100">
                      <label className="text-muted" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      name="password"
                      required
                    />
                    <div className="invalid-feedback">Password is required</div>
                  </div>
                  {errorMsg ? (
                    <div
                      className="mb-3"
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {errorMsg}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex align-items-center">
                    <button type="submit" className="btn btn-primary ms-auto">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
