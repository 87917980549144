import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import Redirect from "../pages/Redirect";
import { useAuth } from "../context/auth";

export default function UserRoute() {
  const [access, setAccess] = useState(false);
  const [auth, setAuth] = useAuth();

  useEffect(() => {
    const authCheck = async () => {
      const res = await axios.get("/api/v1/auth/user-auth");
      if (res.data.access) {
        setAccess(true);
      } else {
        setAccess(false);
      }
    };
    if (auth?.token) authCheck();
  }, [auth?.token]);

  return access ? <Outlet /> : <Redirect path="" />;
}
