import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./../components/Layout/Layout/Layout";
import { useAuth } from "../context/auth";

const HomePage = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  return (
    <Layout title={"Home Page"}>
      <main id="main" className="main">
        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              {auth?.user !== null ? (
                <div className="row">
                  {/* <div className="col-md-4">
                    <div className="card info-card revenue-card">
                      <div className="card-body">
                        <h5 className="card-title text-center">RCV Bible</h5>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="card info-card">
                      <div className="card-body">
                        <h5
                          className="card-title text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/auth/ministry")}
                        >
                          Ministry Books
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="card info-card">
                      <div className="card-body">
                        <h5 className="card-title text-center">属灵书报</h5>
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : (
                <div className="info-card">
                  <div className="card-body">
                    <div
                      className="card-title text-center"
                      style={{ fontSize: "28px" }}
                    >
                      Redeeming the time, because the days are evil
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default HomePage;
